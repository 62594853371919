.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.banner {
  width: 60%;
  margin-bottom: 25px;
}

#upload-container {
  margin-top: 25px;
}
